import * as Sentry from "@sentry/nextjs";
import { NextPage } from "next";
import NextErrorComponent from "next/error";

interface Props {
  statusCode: number;
}
const CustomErrorComponent: NextPage<Props> = ({ statusCode }) => {
  return <NextErrorComponent statusCode={statusCode} />;
};

CustomErrorComponent.getInitialProps = async (contextData) => {
  await Sentry.captureUnderscoreErrorException(contextData);

  return NextErrorComponent.getInitialProps(contextData);
};

export default CustomErrorComponent;
